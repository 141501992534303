import React, { Component } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { Trans } from "gatsby-plugin-react-i18next"

import Layout from "../../components/Layout"
import Container from "../../components/Container"
import SEO from "../../components/Seo"
import Card from "../../components/Card"
import Button from "../../components/Button"

import "./style.scss"

class ContactPage extends Component {
  state = {
    name: "",
    email: "",
    message: "",
  }

  encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => alert("Message sent!"))
      .catch(error => alert(error))

    e.preventDefault()

    // Lets track that custom click
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Submit Button",
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "Contact Page",
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value: 1,
    })
  }

  render() {
    const { name, email, message } = this.state
    return (
      <Layout>
        <SEO
          title="Contact"
          keywords={[
            `provalindonusa`,
            `consultant`,
            `advisory`,
            `properti`,
            `jakarta`,
            `contact`,
          ]}
        />
        <div className="contactpage">
          <section className="contactpage__banner">
            <Container>
              <h2>
                <Trans>Contact Page Title</Trans>
              </h2>
              <p>
                <Trans>Contact Page Description</Trans>
              </p>
            </Container>
          </section>
          <section className="contactpage__content">
            <Container className="contactpage__content-container">
              <Card paddingSize="large" hasBorderRadius hasBoxShadow>
                <form
                  className="contactpage__content-form"
                  name="contact"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="contact" />
                  <label hidden>
                    Don’t fill this out:
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                  <label>
                    Name
                    <br />
                    <input
                      className="text-field"
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Budi"
                      value={name}
                      onChange={this.handleChange}
                    />
                  </label>
                  <br />
                  <br />
                  <label>
                    Email
                    <br />
                    <input
                      className="text-field"
                      type="email"
                      id="email"
                      name="email"
                      placeholder="budi@gmail.com"
                      value={email}
                      onChange={this.handleChange}
                    />
                  </label>
                  <br />
                  <br />
                  <label>
                    Message
                    <br />
                    <textarea
                      className="text-area"
                      id="message"
                      name="message"
                      placeholder="Tell us about your Business"
                      rows="5"
                      value={message}
                      onChange={this.handleChange}
                    />
                  </label>
                  <br />
                  <br />
                  <Button isBlock size="large" type="submit">
                    SUBMIT
                  </Button>
                </form>
              </Card>
            </Container>
          </section>
        </div>
      </Layout>
    )
  }
}

export default ContactPage
